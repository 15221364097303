import { ReactiveMap } from "@solid-primitives/map";
import type { Accessor, Setter } from "solid-js";
import { createComputed, createContext, createSignal, useContext, type ParentProps } from "solid-js";
import type { SetStoreFunction } from "solid-js/store";
import { createStore, type Store } from "solid-js/store";
import type { ComputeOutput } from "~/assets/wasm/single/tbnenergy_wasm";
import type { ComputeFormData } from "~/types/formData";
import type { Polymer } from "~/util/polymer";

type ComputeOutputExtended = ComputeOutput & {
  concentrations?: number[];
};

interface DataStoreContextProps {
  computeFormData: {
    pending: Store<ComputeFormData>;
    updatePending: SetStoreFunction<ComputeFormData>;
    active: Store<ComputeFormData>;
    setActive(data: ComputeFormData): void;
    hasChanges: boolean;
    currentStep: number;
    setCurrentStep(this: void, step: number): void;
  };
  rawData: Accessor<ComputeOutputExtended>;
  updateRawData: Setter<ComputeOutputExtended>;
  polymers: Accessor<Polymer[]>;
  updatePolymers: Setter<Polymer[]>;
  resultInfo: {
    totalConcentration?: number;
  };
  loaders: {
    isLoading: boolean;
    setLoadingState(name: string, state: boolean): void;
    getLoadingState(name: string): boolean;
  };
}

const DataStoreContext = createContext<DataStoreContextProps>();

export const useDataStore = () => {
  const ctx = useContext(DataStoreContext);
  if (!ctx) throw new Error("useDataStore must be used within a DataStoreProvider");
  return ctx;
};

export function DataStoreProvider(props: ParentProps) {
  const defaultFormState: ComputeFormData = {
    max_complexes: "-1",
    max_complex_energy: "0",
    temperature: "25",
    concentrationUnit: "nM",
    energies_inputs: "",
    monomer_inputs: "",
    binding_energy: "-20.6",
  };

  const loaders = new ReactiveMap<string, boolean>();

  const [rawData, setRawData] = createSignal<ComputeOutputExtended>({
    monomers: [],
    complexes: [],
    free_energies: [],
  });
  const [polymers, setPolymers] = createSignal<Polymer[]>([]);

  /* eslint-disable */
  const [dataStore, updateDataStore] = createStore<DataStoreContextProps>({
    computeFormData: {
      pending: Object.assign({}, defaultFormState),
      // @ts-expect-error - this is fine, typescript just really doesn't like it
      updatePending: (...args: any[]) => updateDataStore("computeFormData", "pending", ...args),
      active: Object.assign({}, defaultFormState),
      setActive: (data) => updateDataStore("computeFormData", "active", data),
      hasChanges: false,
      currentStep: 0,
      setCurrentStep: (step) => {
        updateDataStore("computeFormData", "currentStep", step);
      },
    },
    rawData: rawData,
    resultInfo: {},
    updateRawData: setRawData,
    polymers: polymers,
    updatePolymers: setPolymers,
    loaders: {
      isLoading: false,
      setLoadingState(name, state) {
        loaders.set(name, state);
      },
      getLoadingState(name) {
        return loaders.get(name) ?? false;
      },
    },
  });
  /* eslint-enable */

  // totalConcentration handling
  createComputed(() => {
    if (!dataStore.rawData().concentrations) return;
    const totalConcentration = dataStore.rawData().concentrations!.reduce((acc, curr) => acc + curr, 0);
    updateDataStore("resultInfo", "totalConcentration", totalConcentration);
  });

  // general isLoading handling
  createComputed(() => {
    const isLoading = Array.from(loaders.values()).some((v) => v);
    updateDataStore("loaders", "isLoading", isLoading);
  });

  // hasChanges handling
  createComputed(() => {
    const hasChanges = (Object.entries(dataStore.computeFormData.pending) as [keyof ComputeFormData, unknown][]).some(
      ([key, value]) => {
        return value !== dataStore.computeFormData.active[key];
      },
    );

    updateDataStore("computeFormData", "hasChanges", hasChanges);
  });

  return <DataStoreContext.Provider value={dataStore}>{props.children}</DataStoreContext.Provider>;
}
