import { Show, type VoidComponent } from "solid-js";
import type { ComputeFormData } from "~/types/formData";
import { useDataStore } from "./DataStore";

type ChangedIndicatorProps =
  | {
      key: keyof ComputeFormData;
    }
  | {
      show: boolean;
    };

const ChangedIndicator: VoidComponent<ChangedIndicatorProps> = (props) => {
  const { computeFormData: formData } = useDataStore();
  if ("key" in props) {
    if (formData.pending[props.key] == undefined || formData.active[props.key] == undefined) {
      if (import.meta.env.DEV) console.warn(`ChangedIndicator: key "${props.key}" not found in formData`);
      return;
    }
  }

  const isChanged = (key: keyof ComputeFormData) => formData.active[key] !== formData.pending[key];

  return (
    <Show when={"key" in props ? isChanged(props.key) : props.show}>
      <div class="h-2.5 w-2.5 rounded-full bg-primary-400 dark:bg-primary-500" title="Value was modified"></div>
    </Show>
  );
};

export default ChangedIndicator;
